exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-500-js": () => import("./../../../src/pages/500.js" /* webpackChunkName: "component---src-pages-500-js" */),
  "component---src-pages-broadcast-js": () => import("./../../../src/pages/broadcast.js" /* webpackChunkName: "component---src-pages-broadcast-js" */),
  "component---src-pages-cost-coverage-js": () => import("./../../../src/pages/cost-coverage.js" /* webpackChunkName: "component---src-pages-cost-coverage-js" */),
  "component---src-pages-idiopathic-hypersomnia-consider-xywav-treatment-js": () => import("./../../../src/pages/idiopathic-hypersomnia/consider-xywav-treatment.js" /* webpackChunkName: "component---src-pages-idiopathic-hypersomnia-consider-xywav-treatment-js" */),
  "component---src-pages-idiopathic-hypersomnia-diagnosis-js": () => import("./../../../src/pages/idiopathic-hypersomnia/diagnosis.js" /* webpackChunkName: "component---src-pages-idiopathic-hypersomnia-diagnosis-js" */),
  "component---src-pages-idiopathic-hypersomnia-dosing-js": () => import("./../../../src/pages/idiopathic-hypersomnia/dosing.js" /* webpackChunkName: "component---src-pages-idiopathic-hypersomnia-dosing-js" */),
  "component---src-pages-idiopathic-hypersomnia-efficacy-js": () => import("./../../../src/pages/idiopathic-hypersomnia/efficacy.js" /* webpackChunkName: "component---src-pages-idiopathic-hypersomnia-efficacy-js" */),
  "component---src-pages-idiopathic-hypersomnia-js": () => import("./../../../src/pages/idiopathic-hypersomnia.js" /* webpackChunkName: "component---src-pages-idiopathic-hypersomnia-js" */),
  "component---src-pages-idiopathic-hypersomnia-resources-js": () => import("./../../../src/pages/idiopathic-hypersomnia/resources.js" /* webpackChunkName: "component---src-pages-idiopathic-hypersomnia-resources-js" */),
  "component---src-pages-idiopathic-hypersomnia-safety-and-tolerability-js": () => import("./../../../src/pages/idiopathic-hypersomnia/safety-and-tolerability.js" /* webpackChunkName: "component---src-pages-idiopathic-hypersomnia-safety-and-tolerability-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jazzcares-for-xywav-js": () => import("./../../../src/pages/jazzcares-for-xywav.js" /* webpackChunkName: "component---src-pages-jazzcares-for-xywav-js" */),
  "component---src-pages-narcolepsy-comorbidities-js": () => import("./../../../src/pages/narcolepsy/comorbidities.js" /* webpackChunkName: "component---src-pages-narcolepsy-comorbidities-js" */),
  "component---src-pages-narcolepsy-dosing-and-titration-js": () => import("./../../../src/pages/narcolepsy/dosing-and-titration.js" /* webpackChunkName: "component---src-pages-narcolepsy-dosing-and-titration-js" */),
  "component---src-pages-narcolepsy-efficacy-and-safety-js": () => import("./../../../src/pages/narcolepsy/efficacy-and-safety.js" /* webpackChunkName: "component---src-pages-narcolepsy-efficacy-and-safety-js" */),
  "component---src-pages-narcolepsy-js": () => import("./../../../src/pages/narcolepsy.js" /* webpackChunkName: "component---src-pages-narcolepsy-js" */),
  "component---src-pages-narcolepsy-prescriber-resources-js": () => import("./../../../src/pages/narcolepsy/prescriber-resources.js" /* webpackChunkName: "component---src-pages-narcolepsy-prescriber-resources-js" */),
  "component---src-pages-narcolepsy-safety-and-tolerability-js": () => import("./../../../src/pages/narcolepsy/safety-and-tolerability.js" /* webpackChunkName: "component---src-pages-narcolepsy-safety-and-tolerability-js" */),
  "component---src-pages-narcolepsy-sodium-content-js": () => import("./../../../src/pages/narcolepsy/sodium-content.js" /* webpackChunkName: "component---src-pages-narcolepsy-sodium-content-js" */),
  "component---src-pages-narcolepsy-transitioning-from-xyrem-js": () => import("./../../../src/pages/narcolepsy/transitioning-from-xyrem.js" /* webpackChunkName: "component---src-pages-narcolepsy-transitioning-from-xyrem-js" */),
  "component---src-pages-sign-up-js": () => import("./../../../src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-sign-up-thank-you-js": () => import("./../../../src/pages/sign-up-thank-you.js" /* webpackChunkName: "component---src-pages-sign-up-thank-you-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-xywav-xyrem-program-js": () => import("./../../../src/pages/xywav-xyrem-program.js" /* webpackChunkName: "component---src-pages-xywav-xyrem-program-js" */)
}

