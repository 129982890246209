import React, { createContext, useState } from "react"

const AppContext = createContext("")

const AppProvider = ({ children }) => {
  const [isExitModal, setExitModal] = useState(false)
  const [modalLink, setModalLink] = useState(null)
  const [scrollProgressFixed, setScrollProgressFixed] = useState(false)
  const [vaTopic, setVaTopic] = useState(null)
  const [vaSubTopic, setVaSubTopic] = useState(null)
  const [mobileInputFocus, setMobileInputFocus] = useState(false)
  const [activeNavIndexes, setActiveNavIndexes] = useState({
    nav: null,
    sub: null,
  })
  const [videoPlaying, setVideoPlaying] = useState(null)

  return (
    <AppContext.Provider
      value={{
        isExitModal,
        setExitModal,
        modalLink,
        setModalLink,
        scrollProgressFixed,
        setScrollProgressFixed,
        vaTopic,
        setVaTopic,
        vaSubTopic,
        setVaSubTopic,
        mobileInputFocus,
        setMobileInputFocus,
        activeNavIndexes,
        setActiveNavIndexes,
        videoPlaying,
        setVideoPlaying,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export { AppProvider, AppContext }
